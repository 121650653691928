/******************************************/
/******************************************/
/******************************************/
/******************************************/
.otf {
  display: grid;
  @media screen and (max-width: 992px) {

    display: flex;
    flex-direction: column;
  }
  .searcherGlobal {
    background: #fff;

  }

  .formasfarmaceuticas {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    .dcpgrid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .ffdisponibles {
    display: grid;
    grid-template-columns: 1fr;
    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
    }

    .modal-lg {
      width: 80%;
      @media screen and (max-width:992px){
        width:90%;
      }
    }
    .contraindicacionAccordion {
      background: deepskyblue;
    }

    .contraindiacion-title {

    }
    .contraindicacion-description {
      .content {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        .item-content {
          display: grid;
          grid-template-columns: 200px 1fr;
          grid-gap: 20px;
          width: 80%;
          margin: auto;

          @media screen and (max-width: 992px) {
            grid-template-columns: 1fr;
          }

        }
        .labelInfo {
          font-weight: bold;

        }
      }
      a{

          word-break: break-word;

      }

    }
  }

  ul.listdcpf {
    display: grid;
    grid-row-gap: 20px;
  }

  li.dcpf-item {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: minmax(30px, auto);
    grid-row-gap: 20px;
    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
    }
  }
  .urlLink {
    display: block;
  }

  .dcpf-fromLote2 {
    border: 1px solid red;
    list-style: none;
  }

  .boxInfo {

    display: grid;
    grid-template-columns: repeat(2, minmax(320px, 1fr));
    justify-content: center;
    align-items: center;
    height: auto;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    height: auto;
    grid-gap: 20px;

    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
    }
  }
  .boxItem {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50px;
    border: 1px solid black;

    border: none;
    background: $vr_blue_alert;
    color: white;
    border-radius: 4px;

  }

  .viewmore {
    white-space: pre-line;
    display: none;
  }
  .preline {
    white-space: pre-line;
  }

  .nombrePpio {
    font-weight: bold;
    text-transform: capitalize;
  }

  .modal.fade {
    .panel {
      a.contraindicacion-title {
        background-color: $vr_blue;
        color: #FFF;
      }

    }
  }
  .table-striped {
    .detalle-dcp {
      &:hover {

        background: $vr_blue !important;
        .titlemodal {
          color: #fff !important;
        }
      }

      &:nth-child(odd) {
        background: #f9f9f9;
      }
    }

  }

  #vr_searchCIE_form .pp-group .select2-selection {

    border-radius: 5px !important;
    width: 100% !important;
    height: 45px !important;
    border: solid 3px #0083BF !important;
    border-right: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding: 6px !important;
  }

  #vr_searchCIE_form .pp-group .input-group-addon:last-child {
    background: #0083bf;
    border: 0;
    color: #fff;
  }

  #vr_searchCIE_form .pp-group .outstanding_input {
    border-radius: 5px !important;
    width: 100% !important;
    height: 45px !important;
    border: solid 3px #0083BF !important;
    border-right: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding: 6px !important;
  }

  .tag {
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  }

  .panel-default > .panel-heading {
    color: #FFF !important;
    background-color: #286090;
    border-color: #286090;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

.via {

  /* color: black; */
  font-weight: bold;
  /* border: 1px solid black; */
  margin-right: 20px;
  cursor: initial;

}

.infoGeneral {
  display: grid;
  grid-gap: 10px;
  .item-InfoGeneral {
    display: grid;
    grid-template-columns: minmax(200px, 300px) 100px 1fr 1fr;
    justify-content: center;
    align-items: center;
    padding: 10px 3px;
    &:nth-child(odd) {
      background: #f9f9f9;
    }
  }
  .title {
    font-weight: bold;
  }

  @media screen and (max-width: 992px) {
    padding-inline-start: 5px;

    .item-InfoGeneral {
      grid-template-columns: 1fr;
      .viasdiv {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.CIESSelected {
  margin-top: 20px;
  .tag {
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    background-color: $vr_blue;
    color: #FFF;
    margin: 10px;
    padding: 5px;
    height: auto;

  }
}

.btndisabled {

  background: darken($vr_blue, 20%);
  pointer-events: none;

}

/******************************************/
/******************************************/
/******************************************/
/******************************************/

ul.interaccionacomo {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 15px;



  li {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-row: minmax(auto, 100px);
    border-bottom: 1px solid lightgrey;
    padding-bottom: 10px;
  }
  .viasdiv {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .via {
      width: 80%;
      margin-bottom: 10px;
    }
  }
  .alertLevel {

    align-items: center;
    justify-content: center;
    display: flex;
    width: 70%;
    height: 40px;
  }
  .ppioInteracciona, .ppioBuscado, .alertcolumn {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      height: 40px;
    }
    @media screen and (max-width: 992px) {
      justify-content:start;
    }
  }
  @media screen and (max-width: 992px) {
     li {
      display: flex;
      flex-direction: column;

    }
  }

}

.btn-viewMore {
  cursor: pointer;
  font-weight: bold;
  @extend .btn;
  @extend .btn-info;
}

.DCP-item {
  display: grid;
  grid-template-columns: 300px 1fr;
  border-bottom: 1px solid lightgray;

  .detalle-dcp {
    display: grid;
    cursor: pointer;
    grid-template-columns: 1fr;
    padding: 10px;
    &:hover {
      background: $vr_blue;
      .titlemodal {
        color: white !important;
      }

    }
  }
  .title {
    display: flex;
    align-items: center;
    font-weight: bold;
  }

}

.spanSP {
  @extend .via;

}

.contenidoDosis {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  width: 100%;
}

.dosisToxica {

  background: lightcoral;
}

.dosisLimite {

  background: lightyellow;
}

.dosisInfra {

  background: lightblue;
}

.rowDosis {
  padding: 10px;
  display: grid;
  grid-template-columns: 200px 1fr;
}

.dosisdiv {
  h4 {
    text-align: center;
  }
  .title {
    font-weight: bold;
    text-transform: uppercase;
    display: flex;

    align-items: center;
  }
  .contenido {
    display: flex;
    align-items: center;
  }
}

.notinfo {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.content-DCP {
  display: grid;
  grid-template-columns: 1fr;
}

.tools {

  .searcherGlobal {

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    .vr_block {
      display: flex;
      flex-direction: row;
      .formsearch {
        width: 100%;
        .vr_button {
          margin-top: 15px;
        }
      }
      > div {
        margin: 5px 0 !important;
      }
    }
  }
  .panel-default > .panel-heading {
    color: #FFF !important;
    background-color: #286090;
    border-color: #286090;
    height: 60px;
    display: flex;
    //justify-content: center;
    align-items: center;
  }

}

#searchblock-otf {
  > div {
    margin: 5px 0;
  }
}

.clasificacion-buttons {
  display: grid;
  grid-template-columns: repeat(6, minmax(150px, 1fr));
  grid-gap: 5px;
  .button-checkbox {
    width: 100%;
    button {
      min-width: 150px;
    }
  }
}

.filterContraindicaciones {
  margin: 10px;
  display: grid;
  grid-template-columns: repeat(6, minmax(100px, 1fr));
  grid-gap: 5px;
  button {
    width: 100%;
  }
  @media screen and (max-width: 992px) {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    button {
      width: auto;
    }
    .button-checkbox {
      margin: 2px;
    }
  }

}

.otherciesgroup {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.badge__contraindications {
  font-size: 14px;
}

#vr_searchCIE_form .pp-group .select2-selection {

  border-radius: 5px !important;
  width: 100% !important;
  height: 45px !important;
  border: solid 3px #0083BF !important;
  border-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding: 6px !important;
}

#vr_searchCIE_form .pp-group .input-group-addon:last-child {
  background: #0083bf;
  border: 0;
  color: #fff;
}

#vr_searchCIE_form .pp-group .outstanding_input {
  border-radius: 5px !important;
  width: 100% !important;
  height: 45px !important;
  border: solid 3px #0083BF !important;
  border-right: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding: 6px !important;
}

.grid-group-dcsa {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  @media screen and (max-width: 992px) {
    grid-template-columns: 1fr;
  }
}

.alert-level-buttons {
  display: grid;
  grid-template-columns: repeat(5, minmax(200px, 1fr));
  .vr_button_alert {
    width: 85%;
  }

}

@media screen and (max-width: 992px) {
  .modal .alert-level-buttons {
    grid-template-columns: 1fr;
    .button-checkbox{
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 5px auto;
    }
  }
}

.tool-tip {
  display: inline-block;
  width: 100%;
}

.tool-tip [disabled] {
  pointer-events: none;
}

.filterClasificacion {
  .clasificacion-buttons {
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(5, minmax(150px, 1fr));
    grid-gap: 5px;
    align-items: center;
    justify-content: center;
    .button-checkbox {
      width: 100%;
      height: 52px;
      align-items: center;
      justify-content: center;
      display: flex;
    }
    @media screen and (max-width: 992px) {
      grid-template-columns: 1fr;
    }

  }
}

.btn_alert_filter_clasificacion {
  background: #FFF;
  height: 100%;
  width: 100%;
}

.ffdisponibles .panel-group {
  min-height: 350px;
}

.backwhite.vr_block {
  display: block;
  min-height: 200px;
  height: auto;
  padding: 0 15px;
}

.searchResults {
  min-height: 300px;
}