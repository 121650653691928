$vr_blue: #0083BF;
$vr_sky_blue: #1B90C6;
$vr_blue_hover: #3097D1;
$vr_grey_font_menu: #aeb2b7;
$vr_red_alert: #ea5454;
$vr_green_alert: #0fc62e;
$vr_blue_alert: #62b5db;
$vr_yellow_alert: #ddd71c;
$vr_orange_alert: #eabe5d;

html {

  position: relative;
  min-height: 100%;

}

body {

  background-color: #f1f2f7 !important;
  margin: 0;
  font-family: 'Open Sans', sans-serif !important;

}

.header_container {

  background: white;
  padding-bottom: 10px;
  margin-bottom: 50px;

  img {

    width: 260px;
    margin-top: 15px;

  }

}

.vr_block {

  background: white;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 50px;

  .messageAlert {
    margin-top: 20px;
    display: block;
    float: left;
    width: 100%;
    padding: 0 10px;
  }

}

.outstanding_input {

  border-radius: 5px;
  width: 100% !important;
  height: 45px;
  border: none;
  padding: 10px;

}

.textAlignCenter {

  text-align: center;

}

.nav-tabs {

  li {

    background-color: rgba(255, 255, 255, 0.54);

    a:hover {

      background-color: $vr_blue !important;
      color: white !important;

    }

  }

}

.active {

  a {

    background-color: $vr_blue !important;
    color: white !important;

  }

}

.vr_small_title {

  color: black;
  font-weight: bold;

}

#pavspa_div {

  display: none;

}

#vr_all_int {

  display: none;

}

.vr_button {

  height: 51px;
  border: none;
  background: $vr_blue;
  color: white;
  width: 100%;
  border-radius: 4px;

}

.vr_button:hover {

  background: $vr_sky_blue;

}

.twitter-typeahead {

  width: 100%;

}

.vr_alert_block {

  display: none;

}

.vr_blue_button {

  background: $vr_blue !important;
  color: white;

}

.vr_blue_button:hover {

  background: $vr_blue;
  color: white;
  z-index: 0;

}

.vr_btn_group_codes {

  button {

    cursor: default;

  }

}

#vr_right_header {

  color: black;
  line-height: 85px;
  float: right;

  a {

    color: black;
    text-decoration: none;
    cursor: pointer;

  }

  i {

    line-height: 85px;

  }

  span:hover {

    color: $vr_blue_hover;

  }

}

#vr_left_header{
  float: left;
  line-height:85px;
  margin-top:15px;
  margin-left:20px;
  a{
    height:76px;
    display: flex;
    align-items:center;

  }

}

#vr_logo_header {

  float: left;

}

.vr_language_img {

  width: auto !important;
  margin-top: 0px !important;

}

.vr_left_none {

  left: auto;

}

.vr_pointer {

  cursor: pointer;

}

.vr_pointer:hover {

  color: $vr_blue_hover !important;

}

.table-hover {

  tbody {

    tr:hover {

      background-color: rgba(0, 131, 191, 0.25);
      cursor: pointer;

    }

  }

}

#vr_block_groups {

  display: none;

}

.vr_option_search {

  cursor: pointer;

}

.disabled {

  background: #ccc;

}

.disabled:hover {

  background: #ccc;

}

.btn:hover {

  color: white;

}

.vr_profile_menu {

  background: #32323A;
  border-radius: 4px;

  ul {

    list-style: none;
    padding-left: 0px;
    color: $vr_grey_font_menu;

    li {

      cursor: pointer;
      height: 45px;
      line-height: 44px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
      padding-left: 15px;

    }

    li:hover {

      background: #28282E;
      border-radius: 4px;
    }

  }

}

.search_icon:hover {

  color: $vr_blue;

}

.s_display {

  display: none;

}

.vr_red_alert {

  color: $vr_red_alert;

}

.vr_green_alert {

  color: $vr_green_alert;

}

.vr_blue_alert {

  color: $vr_blue_alert;

}

.vr_yellow_alert {

  color: $vr_yellow_alert;

}

.vr_orange_alert {

  color: $vr_orange_alert;

}

.vr_red_button_alert {

  border: none;
  background: $vr_red_alert;
  color: white;
  border-radius: 4px;

}

.vr_green_button_alert {

  border: none;
  background: $vr_green_alert;
  color: white;
  border-radius: 4px;

}

.vr_blue_button_alert {

  border: none;
  background: $vr_blue_alert;
  color: white;
  border-radius: 4px;

}

.vr_yellow_button_alert {

  border: none;
  background: $vr_yellow_alert;
  color: white;
  border-radius: 4px;

}

.vr_orange_button_alert {

  border: none;
  background: $vr_orange_alert;
  color: white;
  border-radius: 4px;

}

.vr_test_button_alert{

}

.social-icons {

  font-size: 25px;
  text-align: right;

  i {

    margin-right: 10px;

  }

}

.tooltip-inner {

  max-width: 500px !important;
  padding: 5px 15px !important;
  text-align: left !important;

}

.vr_btn_menu {

  height: 35px;
  border: none;
  background: rgba(255, 255, 255, 0);

}

.vr_btn_menu:active, .vr_btn_menu:hover, .vr_btn_menu:focus, .open > .dropdown-toggle.btn-primary, .btn-group .open, .dropdown-toggle:active, .dropdown-toggle:focus, .open > .btn-primary.dropdown-toggle:hover, .open > .btn-primary.dropdown-toggle:focus, .btn:focus, .btn:active:focus, .btn.active:focus, .btn-group.open .dropdown-toggle {

  background: rgba(255, 255, 255, 0);
  color: #3097D1;
  border-color: rgba(255, 255, 255, 0);
  box-shadow: none;

  span {

    color: #3097D1;

  }

  i {

    color: #3097D1;

  }

}

.lineHeight0 {

  line-height: 0 !important;

}

.lineHeight20 {

  line-height: 20px !important;

}

.dropdown-menu {

  li {

    a {

      height: 30px !important;

      i {

        line-height: 0px;

      }

    }

  }

}

.marginTop20 {

  margin-top: 20px;

}

.profile-info-text {

  position: relative;
  padding: 20px;
  font-size: 12px;
  height: 326px;

}

.login-info-text {

  position: relative;
  font-size: 12px;
  height: 125px;

}

.login-info-text-1 {

  position: relative;
  font-size: 12px;
  height: 89px;

}

.vr_btn_profile {

  bottom: 0;
  width: 100%;
  margin-bottom: 28px;

}

.vr_btn_login {

  position: absolute;
  bottom: 0;
  width: 92%;

}

.fontSize12 {

  font-size: 12px;

}

.colorWhite {

  color: white;

}

.floatr {

  float: right;

}

.listStyle {

  text-decoration: none;
  cursor: pointer;

}

.listStyle:hover {

  text-decoration: none;

}

.search_all_re, .search_all_re_group {

  cursor: pointer;

}

.indicador_int {

  float: left;
  width: 20%;
  background: #DDDDDD;
  text-align: center;
  padding: 6px 12px;
  height: 36px;
  border-right: 1px solid;

}

.width25 {

  width: 25%;

}

.border_radius_l {

  border-radius: 5px 0 0 5px;

}

.border_radius_r {

  border-radius: 0px 5px 5px 0;
  border-right: none;

}

.btn_filter {

  width: 100px;
  height: 35px;
  float: right;

}

.select2-container {

  width: 100% !important;
  height: 45px !important;

}

.select2-selection {

  border-radius: 5px !important;
  width: 100% !important;
  height: 51px !important;
  border: solid 3px #0083BF !important;
  padding: 6px !important;

}

.select2-selection__arrow {

  margin-top: 9px;
}

.select_groups {

  height: 45px !important;

}

.marginRight5 {

  margin-right: 5px;

}

.marginTop30 {

  margin-top: 30px;

}

.padding0LeftRight {

  padding-right: 0;
  padding-left: 0;

}

.padding8LeftRight {

  padding-right: 8px;
  padding-left: 8px;

}

.cursorPointer {

  cursor: pointer;

}

.vr_button_mini {

  font-size: 13px;
  width: 300px;
  height: 30px;

}

.footer_container {

  background: $vr_blue;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  color: white;
  margin-top: 40px;

  a {

    text-decoration: none;
    color: white;

  }

}

.vr_image {

  width: 100%;

}

.lineHeight150 {
  a {
    text-decoration: underline;
  }
}

@media (max-width: 991px) {

  .indicador_int {

    width: 100%;
    margin-top: 5px;
    border: none;

  }

  .lineHeight150 {

    line-height: inherit;

  }

  .footer_container {

    min-height: 100px;

  }

}

.languages-top-menu {
  display: none;
}

.legalwarning {
  p {
    height: 150px;
    overflow: auto;
  }
}

#vr_search_form, #vr_block_groups, #vr_searchCIE_form , #vr_searchPPioMed, #vf_searchFFMed ,#vr_searchMedicament_form,.formsearch{  .pp-group {
    .select2-selection {
      border-radius: 5px !important;
      width: 100% !important;
      height: 45px !important;
      border: solid 3px #0083BF !important;
      border-right: 0 !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      padding: 6px !important;
    }
    .input-group-addon:last-child {
      background: #0083bf;
      border: 0;
      color: #fff;
    }
    .outstanding_input {
      border-radius: 5px !important;
      width: 100% !important;
      height: 45px !important;
      border: solid 3px #0083BF !important;
      border-right: 0 !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      padding: 6px !important;
    }
  }
}

.ppiosActivos {
  &.list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    li {
      list-style: none;
      margin-right: 10px;
      margin-bottom: 10px;
    }

  }
  button.ppio {
    &.active {

    }
  }
}

.title-FF{
  a{
    display: block;
  }
}


h1 {
  margin-left: 15px;
  margin-bottom: 20px;
}

.orptables {
  list-style: none;
}

.vr_orp_button {
  height: 51px;
  border: none;
  background: $vr_blue_alert;
  color: white;
  width: 100%;
  border-radius: 4px;

  &.active {
    background: $vr_green_alert;
  }

}

@media (min-width: 768px) {
  .orptables {
    .brand-pills > li > a {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    li.brand-nav.active a:after {
      content: " ";
      display: block;
      width: 0;
      height: 0;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 9px solid #428bca;
      position: absolute;
      top: 50%;
      margin-top: -20px;
      left: 100%;
      z-index: 2;
    }
  }
}

.gridhome {

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;

  .box {
    position:relative;
    height: 400px;

    .title{
      padding:0;
      position: absolute;
      z-index:10;
      display: flex;
      align-items:center;
      justify-content:center;
      height:100%;
      width:100%;
      color:#fff;
      display:none;
      margin:0;

    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;


    }
    &:hover{
      img{ filter:brightness(0.4);}
      .title{
        display: block;
        position: absolute;
        z-index:10;
        display: flex;
        align-items:center;
        justify-content:center;
        height:100%;
        width:100%;
        color:#fff;
        top:0;
      }
    }
  }

}



@media (max-width: 768px) {
  .gridhome {
    grid-template-columns: 1fr;


    .box {
      height:200px;
      img{ filter:brightness(0.4);}
      .title{
        display: block;
        position: absolute;
        z-index:10;
        display: flex;
        align-items:center;
        justify-content:center;
        height:100%;
        width:100%;
        color:#fff;
        top:0;
        text-align: center;
        font-size: 2em;
      }
    }

  }

}


.newhomepage{
  .universoDiv {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(240px,1fr));
    grid-gap: 10px;

  }

  .universoDiv>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    /*border:1px solid lightgrey;*/
  }
  .universoDiv .title{
    text-align: center;
    min-height:25px;
  }

  .total {
    align-items: center;
    background-color: white;
    border: 5px solid #008ca9;
    border-radius: 70px;
    color: #008ca9;
    display: flex;
    font-size: 2.2em;
    height: 120px;
    justify-content: center;
    margin: 1em 2em;
    padding: 23px;
    text-align: center;
    width: 120px;
  }
  h1 {
    text-align: center;
  }
  .box.disabled{
   cursor:  not-allowed;
    a{

      pointer-events: none;

  }
  }
}

.tools {
  .vr_block_2{
    margin-bottom:10px;
  }
  .disabled{
    cursor:  not-allowed;
    pointer-events: none;
  }
}
form#formFilterCheck {
  display: grid;
  grid-template-columns: repeat(5,1fr);

  @media screen and (max-width: 992px){
    grid-template-columns: 1fr;
  }

}


.sugerenciaCodes .indicador {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr) );
  margin-top: 20px;

  .btn_sugerencia{
    width:100%;
    min-height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    height: auto;
  }
}

#div_via_pa1, #div_via_pa2{
  min-height:120px;
  height:auto;
}


select.selectclasificacion {
  height: 45px;
  border: solid 3px #0083BF !important;
  width: 100%;
  border-radius: 5px;
}


.searcherGlobal{


}