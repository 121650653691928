
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "~select2/dist/css/select2.css";

@import "~font-awesome/css/font-awesome.css";

@import "vrdocmed";
@import "vrdocmed-ff";
@import "visorpatologias";

@import "universo";
