 .universoDiv {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-gap: 10px;
    
    &#universoGuiasTerapeuticas{
        grid-template-columns:repeat(2, minmax(240px, 1fr));
    }
    
    
    &>div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        -webkit-border-radius: 40px;
        -moz-border-radius: 40px;
        border-radius: 40px;
        /*border:1px solid lightgrey;*/
        justify-content:flex-start;
    }
    
    .title {
        text-align: center;
        min-height: 45px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    
    .total {
        align-items: center;
        background-color: rgb(255, 255, 255);
        border: 5px solid rgb(0, 140, 169);
        border-radius: 110px;
        color: rgb(0, 140, 169);
        display: flex;
        font-size: 3.4em;
        height: 200px;
        justify-content: center;
        margin: 0.35em 2em;
        padding: 23px;
        text-align: center;
        width: 200px;
    }
    
    h1 {
        text-align: center;
    }
    
    .grid2 {
        grid-template-columns: 1fr 1fr;
    }
    
    .grid3 {
        grid-template-columns: repeat(3, 1fr);
    }
    
    .separator {
        background-color: rgb(0, 140, 169);
        height: 3px;
        width: 230px;
        margin: 70px auto;
        
    }
    
    .flex-row {
        
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        border-top: 2px solid rgb(0, 140, 169);
        border-radius: 20px;
        
        
        &:before {           
             
            position: absolute;           
            margin-top: -13px;
            border-bottom: solid 12px rgb(0,140,169);
            border-left: solid 4px rgba(0, 0, 0, 0);
            border-right: solid 4px rgba(0, 0, 0, 0);
        }
    }
    
    .litle {
        display: flex;    
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        .total {
            height: 50px;
            width: 50px;
            font-size: 1em;
        }
        h6{
            text-align: center;
            width: 80%;
        }
        
    }
    
}