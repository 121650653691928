.visorpatologias {
  .SearchResults {
    padding: 10px 20px;
    background-color: #FFF;

  }

  .datos {
    display: flex;
    flex-direction: column;
    width: 80%;
    border: 1px solid rgb(0, 0, 0);
    max-width: 800px;
  }
  .datarow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 10px;
    /* justify-content: center; */
    border-bottom: 1px solid rgb(0, 0, 0);

    .sintomas-detail-pat{
      border-bottom:1px solid lightgrey;
    }

    .data li {
      display: grid;
      grid-template-columns: 9fr 1fr;
      align-items: center;
      list-style: indigo;

      padding: 5px;
    }

  }

  .vr_option_search {

    color: $vr_blue;
    height: 40px;
    border: 1px solid $vr_blue;
    border-radius: 10px 10px 0 0;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    &.active, &:hover {
      background-color: $vr_blue;
      color: #fff;
    }
  }
}

.SymptomsSelected {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px 0;
  justify-content: space-evenly;
  height: auto;
  .tag {
    width: auto;
    padding: 10px;
    margin-bottom: 10px;

  }
}

.pathologyBySymtoms {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;

  .pathology-info {

  }
  .pathology-info-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    &.titles {
      font-weight: bold;
    }
  }
  .bold {
    font-weight: bold;
  }

}

#pathologySearchBlock, #symptomSearchBlock {
  display: none;
}

.initialsblock {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height:50px;
  font-size:1.3em;
}

#accordion-initials {
  .panel-body {
    padding: 5px;

    .rowgrid {
      display: grid;
      grid-template-columns: 400px  1fr;
      grid-gap: 20px;
      &.rowgrid-symptoms{
        grid-template-columns: 300px 1fr 1fr;
      }
      &:nth-child(odd) {
        background-color: rgba(231, 231, 231, 0.4);
      }

      &.title div{
        text-align:center;
        font-weight:bold;

        border-bottom:1px solid black;
      }
      .descripInfo{
        display: flex;
        flex-direction:column;
        padding:25px;
        justify-content:center;
      }
    }
  }

  .pathologyName {
    display: flex;
    align-items: center;
    display: grid;
    grid-template-columns: 3fr 1fr;
    justify-content: center;
  }

}

.btnVisorPatologies {
  height: auto;
  padding: 5px;
  margin-left: 20px;
}

.nav-tabs.nav-justified > li > a {
  border-bottom: none;
}

.sintomas-detail-pat {
  display: grid;
  grid-template-columns: 1fr 100px;

}

.showInfoSintomas {
  display: grid;
  grid-template-columns: 1fr 100px;
  width: 90%;
}

.tablesintomas {
  width: 100%;
  .title-patologia {

    width: 50%;
  }
  .num-sintomas {
    width: 25%;
  }
  .porcentaje-sintomas {
    width: 25%;
  }
  .infoPatologia {
    display: grid;
    grid-template-columns: 3fr 1fr;
    width: 80%;
  }
}
.infotext{
  font-weight:bold;
  margin:20px ;
  font-size:20px;
}